/* jshint esversion: 6, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global document, globalBaseUrl */

import $ from 'jquery';

import { Sticky } from './modules/sticky.js';
import { ScrollTo } from './modules/scroll-to.js';
import { Lightbox } from './modules/lightbox.js';
import { Animation } from './modules/animation.js';
import { GoogleMap } from './modules/map.js';
import { Parallax } from './modules/parallax.js';
import { Slick } from './modules/slick.js';
import { dontgo } from './modules/dontgo.js';

class Site {
	constructor() {
		$(document).ready(this.onReady.bind(this));

		this.scrollTo = new ScrollTo();
		this.scrollTo.init();

		this.sticky = new Sticky();
		this.sticky.init();

		this.googleMap = new GoogleMap();
		this.googleMap.init();

		this.animation = new Animation();
		this.animation.init();

		this.parallax = new Parallax();
		this.parallax.init();

		this.urlBase = globalBaseUrl + '/images/';
	}

	onReady() {
		Lightbox();
		Slick();
		dontgo();

		var floorplanControl = $(".floorplans-navigation__item");
		var imageClass = $(".building-vector");
		var imageLink = $(".floorplans-navigation__link--view-plan");

		floorplanControl.mouseover(function(){
			$(this).find(imageLink).removeClass("is-invisible-tablet");
			var floor = $(this).find(".floorplans-navigation__link--view-plan").parent().attr('class').split(' ');
			imageClass.attr("src", globalBaseUrl + 'images/building/' + floor[1] + '.svg');
		});

		floorplanControl.mouseout(function() {
			$(this).find(imageLink).addClass("is-invisible-tablet");
			imageClass.attr("src", globalBaseUrl + 'images/building/building.svg');
		});

		$(".floorplan__toggle").click(function(){
			$(this).closest('.column').find('.floorplan').toggleClass('floorplan--hide');
		});

		// nav overlay added and removed on click.
		$('.js-open-nav').click(function (){
	      $('html').addClass('nav-active');
		});

		$('.js-close-nav').click(function (){
	       $('html').removeClass('nav-active');
		});


		// disable overlay when nav item is selected
		$('.nav-container ul li').click(function (){
		 	$('html').removeClass('nav-active');
		});
	}
}

new Site();
