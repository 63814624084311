/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */

import dontGo from 'dont-go';

export function dontgo() {

	dontGo({
	  title: 'OSW',
	  faviconSrc: './images/favicons/favicon-32x32--2.png',
	  timeout: 1000 //5 seconds
	});

}