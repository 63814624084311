/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */

import $ from 'jquery';
import _ from 'lodash';
import Rellax from 'rellax';

export class Parallax {
	constructor() {
		this.instance = undefined;
  	this.relaxSelector = '.rellax';
    this.debounceDelay = 15; // debounce delay for both resize event and scroll event
	}

  init() {
    var _this = this;

    if (document.querySelector(this.relaxSelector) !== null) {
	    _this.initRellax();
	    _this.bind();
    }
  }

  bind() {
    var _this = this;

    $(window).on('resize', _.debounce(function() {
      _this.initRellax();
    }, _this.debounceDelay));
  }

  initRellax() {
    var _this = this;
		const isMobile = $(window).width() > 479 ? false : true;

		this.instance = new Rellax(_this.relaxSelector, {
			breakpoints: [576, 768, 1231],
			horizontal: isMobile
		});
  }
}
