/* jshint esversion: 6, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global document */

import $ from 'jquery';
import _ from 'lodash';
import animateScrollTo from 'animated-scroll-to';

export class ScrollTo {
	constructor() {
  	this.onloadDelay = 500;
  	this.debounceDelay = 50;

    this.scrollOptions = {
      // Indicated if scroll animation should be canceled on user action (scroll/keypress/touch)
      // if set to "false" user input will be disabled until scroll animation is complete
      cancelOnUserAction: true,

      // Animation easing function, with "easeOutCubic" as default
      // easing: t => (--t) * t * t + 1,

      // Maximum duration of the scroll animation
      maxDuration: 3000,

      // Minimum duration of the scroll animation
      minDuration: 250,

      // Duration of the scroll per 1000px
      speed: 600,

      // Vertical scroll offset
      // Practical when you are scrolling to a DOM element and want to add some padding
      verticalOffset: -117
    };
	}

  init() {
    var _this = this;

    if (location.hash) { // if loading with hash
      setTimeout(function() { // handle scrollto after a delay
        _this.scrollToId(location.hash);
      }, _this.onloadDelay);
    }

    this.bind();
  }

  bind() {
    var _this = this;

    window.addEventListener('hashchange', function() { // on hash change handle scroll
      _this.scrollToId(location.hash);
    }, false);
  }

  checkHeaderHeight() {
    const $header = $('.header--beta');

    this.scrollOptions.verticalOffset = 0 - $header.innerHeight(); // set offset to match sticky header height
  }

  scrollToId(target) {
    var _this = this;

    _this.checkHeaderHeight(); // check before scroll (this is more efficient than checking on resize etc events

    if (target === '#area') { // hack for Area link
      animateScrollTo(0, _this.scrollOptions);
    } else {
      const selector = '[data-scroll-target="' + target.replace('#', '') + '"]';
      const targetElement = document.querySelector(selector);

      if (targetElement !== null) {
        animateScrollTo(targetElement, _this.scrollOptions);
      }
    }
  }
}