/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global window, globalBaseUrl, google, occupiersData, barsData, hotelsData, healthData */

import $ from 'jquery';
import _ from 'lodash';

import { mapStylesArray } from 'map-styles.js';

export class GoogleMap {
  constructor() {
    this.$map = $('#map');
    this.markers = [];
    this.locations = [];
    this.urlBase = globalBaseUrl + '/images/map-markers/';
    this.boundsPadding = 50;
  }

  init() {
    if (this.$map.length) {
      this.locations['localoccupiers'] = occupiersData[0];
      this.locations['barsrestaurants'] = barsData[0];
      this.locations['gymshotels'] = hotelsData[0];
      this.locations['healthfitness'] = healthData[0];
      this.$mapDropdownControl = $('.map-dropdown__control .button');

      this.layoutLists();
      this.bind();
    }
  }

  layoutLists() {
    var num_cols = 4;
    var listItem = 'li';
    var listClassOne = 'column';
    var listClassTwo = 'is-3-widescreen';
    var listClassThree = 'is-6-tablet';
    var listClassFour = 'map-dropdown__menu-list';

    $('.map-dropdown__menu').each(function() {
      var items_per_col = new Array(),
      items = $(this).find(listItem),
      min_items_per_col = Math.floor(items.length / num_cols),
      difference = items.length - (min_items_per_col * num_cols);

      for (var i = 0; i < num_cols; i++) {
        if (i < difference) {
          items_per_col[i] = min_items_per_col + 1;
        } else {
          items_per_col[i] = min_items_per_col;
        }
      }

      for (var i = 0; i < num_cols; i++) {

        $(this).append($('<ol></ol>').addClass(listClassOne).addClass(listClassTwo).addClass(listClassThree).addClass(listClassFour));

        for (var j = 0; j < items_per_col[i]; j++) {
          var pointer = 0;
          for (var k = 0; k < i; k++) {
            pointer += items_per_col[k];
          }
          $(this).find('.' + listClassOne).last().append(items[j + pointer]);
        }
      }
	  });
  }

  bind() {
    const _this = this;

    window.addEventListener('google-maps-ready', function () {
      _this.setupMap();

  		_this.$mapDropdownControl.click(function() {
        var category = $(this).data('category');

  			if ($(this).hasClass('is-active')) { // if this section is active then remove markers
    			_this.clearMarkers();
  			} else { // otherwise add them
    			_this.addMarkers(category);
  			}

  			_this.toggleDropdown(category); // and trigger the list display toggling
  		});
    }, false);
  }

  toggleDropdown(category) {
		var dropdown = $('.map-dropdown__items[data-category="'+ category +'"]');
		var button = $('.button[data-category="'+ category +'"]');

		dropdown.toggleClass('is-active').siblings().removeClass('is-active');
		button.toggleClass('is-active').closest('.column').siblings().find('.button').removeClass('is-active');

		if((button).hasClass('is-active')) {
			button.closest('.columns').addClass('map-dropdown__control--active');
		} else {
			button.closest('.columns').removeClass('map-dropdown__control--active');
		}
  }

  addMarkers(category) {
    var _this = this;

    _this.clearMarkers();
    _this.bounds = new google.maps.LatLngBounds(); // setup blank bounds to track area of the markers

    var numeration = 1; // this is to label our markers

    _.forEach(_this.locations[category], function(value, key) {
      var latlongRaw = value.split(','); // split the bad data into usable
      var latLng = {lat: parseFloat(latlongRaw[0]), lng: parseFloat(latlongRaw[1])}; // parse into lat long object

      var infowindow = new google.maps.InfoWindow({
        content: key
      });

      var marker = new google.maps.Marker({
        position: latLng,
        map: _this.googleMap,
        zIndex: 0,
        icon: {
          url: _this.urlBase + 'map-marker--' + numeration + '.svg',
          anchor: new google.maps.Point(25,50),
          scaledSize: new google.maps.Size(40,40)
        }
      }); // create maps marker and add to map

      marker.addListener('mouseover', function() {
        infowindow.open(_this.googleMap, marker);
      });

      marker.addListener('mouseout', function() {
        infowindow.close();
      });

      _this.markers.push(marker); // add to markers array for reference

      _this.bounds.extend(latLng); // extend the bounds tracking markers total area

      numeration++; // iterate numberation label
    });

    _this.googleMap.fitBounds(_this.bounds, _this.boundsPadding); // fit map to the area of all the markers
  }

  clearMarkers() {
    var _this = this;

    for (var i = 0; i < _this.markers.length; i++) {
      _this.markers[i].setMap(null); // remove marker from map
    }

    this.markers = []; // delete marker references
  }

  setupMap() {
    var _this = this;

  	this.googleMap = new google.maps.Map(this.$map[0], {
  		center: {lat: 51.5282051, lng: -0.0910556},
  		zoom: 16,
  		styles: mapStylesArray,
  		mapTypeControlOptions: {
  			mapTypeIds: ['roadmap']
  		},
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
  	});

    this.OSWmarker = new google.maps.Marker({
      position: {lat: 51.52826, lng: -0.09113},
      map: _this.googleMap,
      zIndex: 10,
      icon: {
        url: _this.urlBase + 'map-marker--OSW.svg',
        anchor: new google.maps.Point(50, 50),
        scaledSize: new google.maps.Size(150, 70)
      }
    });

  }
}