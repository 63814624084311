/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global connectivity_data, amenities_data */

import _ from 'lodash';
import lottie from 'lottie-web';
import scrollMonitor from 'scrollmonitor';

export class Animation {
	constructor() {
		this.animationStaggerDelay = 250;
		this.animations = [];
		this.containerScrollMonitor = undefined;
		this.containerId = undefined;
	}

  init() {
		if (typeof amenities_data != 'undefined') { // for index.php
			this.setupAnimations(amenities_data, 'ameneties-container');
		}

		if (typeof connectivity_data != 'undefined') { // for area.php
			this.setupAnimations(connectivity_data, 'connectivity-container');
		}
  }

  setupAnimations(data, containerId) {
    var _this = this;

    _this.animationsToLoad = data.length; // how many animations do we need to know loaded
    _this.containerId = containerId; // set id to class for later

		_.forEach(data, function(value) {
			const icon = document.getElementById(value);
			const animation = lottie.loadAnimation({
				container: icon,
				renderer: 'svg',
				loop: true,
				autoplay: false,
				path: 'animations/' + value + '.json'
			}); // setup each item as a lottie web animation

			animation.addEventListener('DOMLoaded', function() { // watch for the full loaded event and trigger handler
				_this.handleAnimationLoaded();
			});

			_this.animations.push(animation); // add animation to array of animations
		});
  }

  handleAnimationLoaded() {
	  this.animationsToLoad--; // one has loaded to delete from list

		if (this.animationsToLoad === 0) { // if there are not more to load then we are done
			this.animationsReady();
		}
  }

  animationsReady() {
    var _this = this;

	  this.containerScrollMonitor = scrollMonitor.create(document.getElementById(this.containerId), {top: -(scrollMonitor.viewportHeight / 3), bottom: -(scrollMonitor.viewportHeight / 3)}); // setup scroll monitor on the container element

    this.containerScrollMonitor.enterViewport(function() { // on enter viewport
			_this.triggerPlay();
    });
  }

  triggerPlay() {
    var _this = this;

	  const playlist = _.shuffle(this.animations); // shuffle animations order randomly

	  _.each(playlist, function(animation, index) { // for each animation
		  setTimeout(function() { // set up a timeout each iteration with a higher timeout value
			  animation.play();
		  }, _this.animationStaggerDelay * (index + 1));
	  });
  }
}
