/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */

import $ from 'jquery';
import lightbox from 'lightbox2';

export function Lightbox() {

	lightbox.option({
		'resizeDuration': 200,
		'wrapAround': true,
		'disableScrolling': true,
		'albumLabel' : '%1 / %2'
	});

	$(".floorplan__lightbox-toggle").click(function(){
		$('.floorplan__lightbox').trigger('click');
	});
}