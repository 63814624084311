/* jshint esversion: 6, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global document */

import $ from 'jquery';
import _ from 'lodash';

export class Sticky {
	constructor() {
    this.headerHeight = 200; // basic default is reset on ready and resize
    this.scrollThreshold = 200; // basic default is reset on ready and resize
    this.debounceDelay = 15; // debounce delay for both resize event and scroll event
    this.removeStickyThresold = 30; // pixel value to when to remove the sticky header above the trigger threshold. this is because some thing change height when sticky header is active and it can cause document height bouncing
	}

  init() {
    this.hasParallax = $('.parallax__container').length; // if there is a parallax container we change the logic

    this.bind();
  }

  bind() {
    var _this = this;

    $(document).ready(function() {
      _this.setHeights();
    });

    $(window).on('scroll', _.debounce(function() {
      _this.handleScroll();
    }, _this.debounceDelay));

    $(window).on('resize', _.debounce(function() {
      _this.setHeights();
    }, _this.debounceDelay));

  }

  setHeights() {
    this.headerHeight = $('.header--nav').outerHeight(); // get header height

    if (this.hasParallax) {
      this.scrollThreshold = this.headerHeight + $('.parallax__container').outerHeight(); // if parallax add additional text to the threshold
    } else {
      this.scrollThreshold = this.headerHeight; // otherwise just header height
    }
  }

  handleScroll() {
    if ($(window).scrollTop() > this.scrollThreshold) { // if scroll is below threshold then add class
      $('body').addClass('sticky-header');
    }

    if ($(window).scrollTop() < (this.scrollThreshold - this.removeStickyThresold)) { // if scroll is above trigger threshold and the additional flexibility then remove class
      $('body').removeClass('sticky-header');
    }
  }
}
