/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */

import $ from 'jquery';
import slick from 'slick-carousel';

export function Slick() {

	var $status = $('.carousel__count');
	var $slickElement = $('.carousel__slides');

	$slickElement.on('init reInit afterChange', function(event, slick, currentSlide){
		//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		var i = (currentSlide ? currentSlide : 0) + 1;
		$status.text(i + ' / ' + slick.slideCount);
	});

	$slickElement.slick({
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 4500,
		lazyLoad: 'ondemand',
		nextArrow: '.carousel__pagination--next',
		prevArrow: '.carousel__pagination--prev'
	});
}

